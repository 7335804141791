@import "Assets/Styles/colours.scss";

.card {
    margin: 20px;
    background-color: $white;
    border-radius: 0.25rem;
}

.tracking-container {
    .first-card {
        margin: 0 20px 20px 20px;
    }
}

.card-container {
    color: #000;

    table {
        width: 100%;
        border-spacing: 0px;
        font-family: "Source Sans Pro", sans-serif;

        tr {
            height: 37.33px;

            &:last-of-type th {
                border-radius: 0.25rem;
            }
        }

        th {
            font-size: 14px;
            font-weight: bold;
            color: $primary;
            width: 35%;
            background-color: #F2F2F2;
        }

        td {
            font-size: 14px;
            word-break: break-all;
            padding: 5px;
            color: #4D4D4D;
        }
    }
}