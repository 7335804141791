.footer-logo {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;

    .image {
        max-width: 40%;
        display: block;
        margin: 5px auto 10px auto;
    }
}
