@import "Assets/Styles/colours.scss";

.card-subcontainer {
    text-align: center;
    font-family: "Montserrat";
    font-size: 12px;

    .elipsis {
        padding: 10px 0px;

        div {
            border-right: 2px dashed $primary;
            width: 0px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            height: 24px;
        }
    }
}