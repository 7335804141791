@import "Assets/Styles/colours.scss";

.copied-container {
    width: 100%;
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    box-shadow: 0 4px 2px -2px $primary-offset;
    background-color: $primary-offset;

    div {
        margin: 20px;
        padding: 19px;
        border-radius: 0.25rem;
        text-align: center;
        background-color: $secondary;
        opacity: 0.9;
        color: $white;
        font-family: Arial;
        text-transform: uppercase;
    }
}