@import "Assets/Styles/colours.scss";

.search-container {
    color: #343B45;
    display: flex;
    position: relative;

    input {
        padding: 11px;
        width: 100%;
        font-size: 16px;
        box-sizing: border-box;
        border: none;
        border-radius: 0.25rem;
        text-indent: 50px;
        margin: 20px 20px 0 20px;

        &:focus {
            outline: 0 none;
        }
    }

    button {
        width: 5%;
        position: absolute;
        height: 40.67px;
        background-color: $white;
        border: 0;
        cursor: pointer;
        border-radius: 0.25rem;
        top: 20px;
        left: 22px;

        img {
            height: 20px;
            width: 21px;
            cursor: pointer;
        }

        &:focus {
            outline: none;
        }
    }
}
.search-box-container {
    width: 100%;
    background-color: $primary-offset;
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
}
