.tracking-container {
	order: 0;
	flex: 0 1 auto;
	align-self: auto;
	width: 100%;
	height: 100%;
    overflow-y: auto;
    overflow-x: none;
}

.scrollbars {
    height: auto !important; // `height: 100%` is added by scrollbars library, which breaks flex
    flex: 1;

    > div {
        scroll-behavior: smooth;
    }
}
