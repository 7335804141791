@import "Assets/Styles/colours.scss";

.icon {
    width: 10%;
    padding-right: 5px;

    button {
        padding: 0;
        border: none;
        background-color: $white;
        cursor: pointer;
        float: right;

        img {
            width: 16px;
            height: 16px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: 7px;
            float: right;
        }
    }

    .history {
		background-color: #F2F2F2;
	}
}