@import "Assets/Styles/colours.scss";

.card-header {
    padding: 7px 7px 7px 15px;
    color: $white;
    background-color: $secondary;
    font-family: "Montserrat";
    font-weight: bold;
    text-transform: uppercase;
    font-size: 15px;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}