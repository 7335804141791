@import "Assets/Styles/colours.scss";

.first-th {
    border-top-left-radius: 0.25rem;
}

.last-th {
    border-bottom-left-radius: 0.25rem;
}

.icon-container {
    display: flex;
    
    .icon {
        width: 24px;
        height: 30px;
        padding-left: 5px;

        img {
            width: 16px;
            height: 16px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: 7px;
        }
    }

    .label {
        line-height: 30px;
    }
}

.copy-container {
    display: flex;
    
    .icon {
        width: 10%;
        padding-right: 5px;

        button img {
            width: 16px;
            height: 16px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: 7px;
            float: right;
        }
    }

    button {
        padding: 0;
        border: none;
        background-color: $white;
        cursor: pointer;
    }

    .label {
        line-height: 30px;
        padding-right: 5px;
        width: 90%;
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .label-no-wrap {
        white-space: normal;
        word-break: normal;
    }

    .label-elipsis {
        max-width: 169px;
    }
}