@import "Assets/Styles/colours.scss";

.yodel-logo-container {
    margin: 20px;
    padding: 15px 15px;
    border-radius: 0.25rem;
    background-color: $white;

    img {
        border: 0;
        max-width: 30%;
        height: auto;
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}