@import "Assets/Styles/colours.scss";

.init-container {
    margin: 20px;
    padding: 15px 15px;
    border-radius: 0.25rem;
    background-color: $white;
    font-family: Arial;
    color: #4A4A4A;
    font-size: 16px;
    word-break: break-word;

    .rm-logo img {
		max-width: 25%;
		height: auto;
		width: auto;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.p2p-logo img {
		max-width: 25%;
		height: auto;
		width: auto;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.yodel-logo {
		padding: 7px 0px;

		img {
			max-width: 30%;
			height: auto;
			width: auto;
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.dpd-logo {
		margin: 20px;
	
		img {
			border: 0;
			max-width: 30%;
			height: auto;
			width: auto;
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
	}
	div {
	    text-transform: uppercase;
		font-weight: bolder;
		font-size: 20px;
		margin-bottom: 10px;
		color: #4D4D4D;
	}
}