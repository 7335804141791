.open-link-container {
    width: 10%;

    a img {
        width: 16px;
        height: 16px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 7px;
        float: left;
    }
}