@import "Assets/Styles/colours.scss";

.status {
    padding: 5px;
    color: #616161;
    font-weight: bold;

    .first-status {
        color: $secondary;
    }
}