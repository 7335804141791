body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.container {
  background-color: #582c83;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.search-container {
  color: #343B45;
  display: flex;
  position: relative; }
  .search-container input {
    padding: 11px;
    width: 100%;
    font-size: 16px;
    box-sizing: border-box;
    border: none;
    border-radius: 0.25rem;
    text-indent: 50px;
    margin: 20px 20px 0 20px; }
    .search-container input:focus {
      outline: 0 none; }
  .search-container button {
    width: 5%;
    position: absolute;
    height: 40.67px;
    background-color: #ffffff;
    border: 0;
    cursor: pointer;
    border-radius: 0.25rem;
    top: 20px;
    left: 22px; }
    .search-container button img {
      height: 20px;
      width: 21px;
      cursor: pointer; }
    .search-container button:focus {
      outline: none; }

.search-box-container {
  width: 100%;
  background-color: #582c83;
  order: 0;
  flex: 0 1 auto;
  align-self: auto; }

.footer-logo {
  order: 0;
  flex: 0 1 auto;
  align-self: auto; }
  .footer-logo .image {
    max-width: 40%;
    display: block;
    margin: 5px auto 10px auto; }

.copied-container {
  width: 100%;
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  box-shadow: 0 4px 2px -2px #582c83;
  background-color: #582c83; }
  .copied-container div {
    margin: 20px;
    padding: 19px;
    border-radius: 0.25rem;
    text-align: center;
    background-color: #009688;
    opacity: 0.9;
    color: #ffffff;
    font-family: Arial;
    text-transform: uppercase; }

.rm-logo-container {
  margin: 20px;
  padding: 8px 15px;
  border-radius: 0.25rem;
  background-color: #ffffff; }
  .rm-logo-container img {
    border: 0;
    max-width: 20%;
    height: auto;
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto; }

.yodel-logo-container {
  margin: 20px;
  padding: 15px 15px;
  border-radius: 0.25rem;
  background-color: #ffffff; }
  .yodel-logo-container img {
    border: 0;
    max-width: 30%;
    height: auto;
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto; }

.p2p-logo-container {
  margin: 20px;
  padding: 7px 15px;
  border-radius: 0.25rem;
  background-color: #ffffff; }
  .p2p-logo-container img {
    border: 0;
    max-width: 20%;
    height: auto;
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto; }

.hermes-logo-container {
  margin: 20px;
  padding: 21px 15px;
  border-radius: 0.25rem;
  background-color: #ffffff; }
  .hermes-logo-container img {
    border: 0;
    max-width: 20%;
    height: auto;
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto; }

.doddle-logo-container {
  margin: 20px;
  padding: 21px 15px;
  border-radius: 0.25rem;
  background-color: #ffffff; }
  .doddle-logo-container img {
    border: 0;
    max-width: 20%;
    height: auto;
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto; }

.dpd-logo-container {
  margin: 20px;
  padding: 7px 15px;
  border-radius: 0.25rem;
  background-color: #ffffff; }
  .dpd-logo-container img {
    border: 0;
    max-width: 30%;
    height: auto;
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto; }

.logo-container {
  width: 100%;
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  box-shadow: 0 4px 2px -2px #582c83;
  background-color: #582c83; }

.centered {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 64px;
  height: 64px; }

.lds-roller {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 50px;
  position: relative;
  width: 64px;
  height: 64px; }
  .lds-roller div {
    -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    -webkit-transform-origin: 32px 32px;
            transform-origin: 32px 32px; }
    .lds-roller div:after {
      content: " ";
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #ffffff;
      margin: -3px 0 0 -3px; }
    .lds-roller div:nth-child(1) {
      -webkit-animation-delay: -0.036s;
              animation-delay: -0.036s; }
      .lds-roller div:nth-child(1):after {
        top: 50px;
        left: 50px; }
    .lds-roller div:nth-child(2) {
      -webkit-animation-delay: -0.072s;
              animation-delay: -0.072s; }
      .lds-roller div:nth-child(2):after {
        top: 54px;
        left: 45px; }
    .lds-roller div:nth-child(3) {
      -webkit-animation-delay: -0.108s;
              animation-delay: -0.108s; }
      .lds-roller div:nth-child(3):after {
        top: 57px;
        left: 39px; }
    .lds-roller div:nth-child(4) {
      -webkit-animation-delay: -0.144s;
              animation-delay: -0.144s; }
      .lds-roller div:nth-child(4):after {
        top: 58px;
        left: 32px; }
    .lds-roller div:nth-child(5) {
      -webkit-animation-delay: -0.18s;
              animation-delay: -0.18s; }
      .lds-roller div:nth-child(5):after {
        top: 57px;
        left: 25px; }
    .lds-roller div:nth-child(6) {
      -webkit-animation-delay: -0.216s;
              animation-delay: -0.216s; }
      .lds-roller div:nth-child(6):after {
        top: 54px;
        left: 19px; }
    .lds-roller div:nth-child(7) {
      -webkit-animation-delay: -0.252s;
              animation-delay: -0.252s; }
      .lds-roller div:nth-child(7):after {
        top: 50px;
        left: 14px; }
    .lds-roller div:nth-child(8) {
      -webkit-animation-delay: -0.288s;
              animation-delay: -0.288s; }
      .lds-roller div:nth-child(8):after {
        top: 45px;
        left: 10px; }

@-webkit-keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.card-header {
  padding: 7px 7px 7px 15px;
  color: #ffffff;
  background-color: #009688;
  font-family: "Montserrat";
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.card-subheader {
  background-color: rgba(0, 150, 136, 0.35);
  padding: 7px 7px 7px 15px;
  color: #01695F;
  font-family: "Montserrat";
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px; }

.open-link-container {
  width: 10%; }
  .open-link-container a img {
    width: 16px;
    height: 16px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 7px;
    float: left; }

.icon {
  width: 10%;
  padding-right: 5px; }
  .icon button {
    padding: 0;
    border: none;
    background-color: #ffffff;
    cursor: pointer;
    float: right; }
    .icon button img {
      width: 16px;
      height: 16px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 7px;
      float: right; }
  .icon .history {
    background-color: #F2F2F2; }

.first-th {
  border-top-left-radius: 0.25rem; }

.last-th {
  border-bottom-left-radius: 0.25rem; }

.icon-container {
  display: flex; }
  .icon-container .icon {
    width: 24px;
    height: 30px;
    padding-left: 5px; }
    .icon-container .icon img {
      width: 16px;
      height: 16px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 7px; }
  .icon-container .label {
    line-height: 30px; }

.copy-container {
  display: flex; }
  .copy-container .icon {
    width: 10%;
    padding-right: 5px; }
    .copy-container .icon button img {
      width: 16px;
      height: 16px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 7px;
      float: right; }
  .copy-container button {
    padding: 0;
    border: none;
    background-color: #ffffff;
    cursor: pointer; }
  .copy-container .label {
    line-height: 30px;
    padding-right: 5px;
    width: 90%;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis; }
  .copy-container .label-no-wrap {
    white-space: normal;
    word-break: normal; }
  .copy-container .label-elipsis {
    max-width: 169px; }

.datetime {
  color: #4D4D4D;
  padding: 10px 0px 5px 0px; }

.location-note {
  padding: 5px 0px;
  color: #4D4D4D; }

.status {
  padding: 5px;
  color: #616161;
  font-weight: bold; }
  .status .first-status {
    color: #009688; }

.card-subcontainer {
  text-align: center;
  font-family: "Montserrat";
  font-size: 12px; }
  .card-subcontainer .elipsis {
    padding: 10px 0px; }
    .card-subcontainer .elipsis div {
      border-right: 2px dashed #582B83;
      width: 0px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      height: 24px; }

.card-event .card-container table td {
  background-color: #F2F2F2; }

.card {
  margin: 20px;
  background-color: #ffffff;
  border-radius: 0.25rem; }

.card-container {
  color: #000; }
  .card-container table {
    width: 100%;
    border-spacing: 0px;
    font-family: "Source Sans Pro", sans-serif; }
    .card-container table tr {
      height: 37.33px; }
    .card-container table th {
      font-size: 14px;
      font-weight: bold;
      color: #582B83;
      width: 35%;
      background-color: #F2F2F2; }
    .card-container table td {
      font-size: 14px;
      word-break: break-all;
      padding: 0 5px;
      color: #4D4D4D; }

.card {
  margin: 20px;
  background-color: #ffffff;
  border-radius: 0.25rem; }

.tracking-container .first-card {
  margin: 0 20px 20px 20px; }

.card-container {
  color: #000; }
  .card-container table {
    width: 100%;
    border-spacing: 0px;
    font-family: "Source Sans Pro", sans-serif; }
    .card-container table tr {
      height: 37.33px; }
      .card-container table tr:last-of-type th {
        border-radius: 0.25rem; }
    .card-container table th {
      font-size: 14px;
      font-weight: bold;
      color: #582B83;
      width: 35%;
      background-color: #F2F2F2; }
    .card-container table td {
      font-size: 14px;
      word-break: break-all;
      padding: 5px;
      color: #4D4D4D; }

.yodel-signature {
  max-width: 340px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0px; }

.rm-signature {
  display: block;
  max-width: 50%;
  margin-left: auto;
  margin-right: auto; }

.hermes-signature {
  max-width: 340px;
  display: block;
  min-width: 50%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0 0 0.25rem 0.25rem; }

.hermes-safe-place {
  max-width: 340px;
  display: block;
  min-width: 50%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0 0 0.25rem 0.25rem; }

.error {
  background-color: #dc3232;
  color: #ffffff;
  padding: 10px;
  text-align: center;
  font-size: 13px;
  border-radius: 0.25rem;
  margin: 20px;
  text-transform: uppercase;
  font-family: Arial; }

.tracking-container {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: none; }

.scrollbars {
  height: auto !important;
  flex: 1 1; }
  .scrollbars > div {
    scroll-behavior: smooth; }

.init-container {
  margin: 20px;
  padding: 15px 15px;
  border-radius: 0.25rem;
  background-color: #ffffff;
  font-family: Arial;
  color: #4A4A4A;
  font-size: 16px;
  word-break: break-word; }
  .init-container div {
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 20px;
    margin-bottom: 10px;
    color: #4D4D4D; }
  .init-container span {
    color: #009688;
    font-weight: bold; }
  .init-container a {
    text-decoration: none; }
  .init-container .geeks-logo img {
    max-width: 25%;
    margin-top: 15px;
    margin-left: 0px; }

.init-container {
  margin: 20px;
  padding: 15px 15px;
  border-radius: 0.25rem;
  background-color: #ffffff;
  font-family: Arial;
  color: #4A4A4A;
  font-size: 16px;
  word-break: break-word; }
  .init-container .rm-logo img {
    max-width: 25%;
    height: auto;
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto; }
  .init-container .p2p-logo img {
    max-width: 25%;
    height: auto;
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto; }
  .init-container .yodel-logo {
    padding: 7px 0px; }
    .init-container .yodel-logo img {
      max-width: 30%;
      height: auto;
      width: auto;
      display: block;
      margin-left: auto;
      margin-right: auto; }
  .init-container .dpd-logo {
    margin: 20px; }
    .init-container .dpd-logo img {
      border: 0;
      max-width: 30%;
      height: auto;
      width: auto;
      display: block;
      margin-left: auto;
      margin-right: auto; }
  .init-container div {
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 20px;
    margin-bottom: 10px;
    color: #4D4D4D; }

.home-page-container {
  height: 100%;
  order: 0;
  flex: 0 1 auto;
  align-self: auto; }

